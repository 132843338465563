export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_CASH = 'cash';
export const PAYMENT_TYPE_CARD_WHEN_RECEIVED = 'card_when_received';

export const LOCAL_STORAGE_KEY_MODE = 'useDeliveryMode';

/**
 * Дотсавка
 */
export const MODE_DELIVERY = 'delivery';

/**
 * В заведении
 */
export const MODE_LOCAL = 'local';

export const PAYMENT_TYPE_LABELS = {
  [PAYMENT_TYPE_CARD]: 'shop.payments.card',
  [PAYMENT_TYPE_CASH]: 'shop.payments.cash',
  [PAYMENT_TYPE_CARD_WHEN_RECEIVED]: 'site.payments.cardCourierPaymentMethod',
};
