import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Script from 'next/script';

import { CLASS_NAME_FOR_HERE_API_DOM_ELEMENT, GA_TRACKER_ID } from '../../../helpers/constants';

export default class CustomJS extends React.Component {
    static propTypes = {
      customJS: PropTypes.string,
      router: PropTypes.shape({
        events: PropTypes.shape({
          off: PropTypes.func,
          on: PropTypes.func,
        }),
      }).isRequired,
    }

    static defaultProps = {
      customJS: null,
    }

    constructor(props) {
      super(props);

      const { customJS } = props;
      const gtagCodes = this.getGtag(customJS);
      const { metaTagsProps, customJsWithoutMeta } = this.getMeta(customJS);

      this.state = {
        gtagCodes,
        metaTagsProps,
        customJsWithoutMeta,
      };
    }

    componentDidMount() {
      const { router } = this.props;
      router.events.on('routeChangeComplete', this.handleRouteChange);
    }

    componentWillUnmount() {
      const { router } = this.props;
      router.events.off('routeChangeComplete', this.handleRouteChange);
    }

    handleRouteChange = (url) => {
      const { gtagCodes } = this.state;

      // Пушим для установленных трекеров что страница изменилась
      if (window.gtag) {
        window.gtag('config', GA_TRACKER_ID, {
          page_path: url, // eslint-disable-line camelcase
          custom_map: { dimension1: 'abTest' }, // eslint-disable-line camelcase
        });
        window.gtag('event', 'setCustomDimension', { abTest: 'newShop' });

        gtagCodes.forEach((gaId) => {
          window.gtag('config', gaId, { page_path: url }); // eslint-disable-line camelcase
        });
      }
    }

    /**
     * Возвращает все трекинг коды аналитики пользователя
     *
     * @param customJS
     * @returns {[]}
     */
    getGtag = (customJS) => {
      const regex = /gtag\('config', ?'(.*)'\)/gm;
      const trackingCodes = [];
      let m;

      while ((m = regex.exec(customJS)) !== null) { // eslint-disable-line no-cond-assign
        if (m.index === regex.lastIndex) regex.lastIndex += 1; // Чтобы не зациклиться

        const [, trackingId] = m;

        trackingCodes.push(trackingId);
      }

      return trackingCodes;
    }

    /**
     * Получает мета теги из скрипта пользователя
     *
     * @param customJS
     * @returns {{metaTagsProps: [], customJsWithoutMeta: string}}
     */
    getMeta = (customJS) => {
      const regex = /<meta .*>/gm;
      const metaTags = [];
      const metaTagsProps = [];
      let m;
      let customJsWithoutMeta = customJS || '';

      while ((m = regex.exec(customJS)) !== null) { // eslint-disable-line no-cond-assign
        if (m.index === regex.lastIndex) regex.lastIndex += 1;

        const [tag] = m;

        metaTags.push(tag);
      }

      const propsRegex = /(name|content)="(.*?)"/mg;

      metaTags.forEach((tag) => {
        // Убираем из скрипта мета теги, чтобы поместить из в <head>
        customJsWithoutMeta = customJsWithoutMeta.replace(tag, '');

        const tagProps = {};

        while ((m = propsRegex.exec(tag)) !== null) { // eslint-disable-line no-cond-assign
          if (m.index === propsRegex.lastIndex) propsRegex.lastIndex += 1;

          const [, propName, propValue] = m;
          tagProps[propName] = propValue;
        }

        metaTagsProps.push(tagProps);
      });

      return {
        customJsWithoutMeta,
        metaTagsProps,
      };
    }

    render() {
      const { metaTagsProps, customJsWithoutMeta } = this.state;

      return (
        <>
          <Head>
            {/* Подключаем нашу гугл аналитку */}
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKER_ID}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${GA_TRACKER_ID}', { custom_map: {'dimension1': 'abTest'} });
                            gtag('event', 'setCustomDimension', { abTest: 'newShop' });
                        `,
              }}
            />

            {metaTagsProps.map(({ name, content }) => <meta key={name + content} name={name} content={content} />)}
          </Head>

          {/* Мы специально игнорируем тут ворнинг реакта, потому что GA добавит в div свои скрипты */}
          {/* Также мы не можем запихнуть это в head потому что рекат не умеет рендерить html без div */}
          {Boolean(customJsWithoutMeta) && (
            <div
              suppressHydrationWarning
              className="custom-js"
              dangerouslySetInnerHTML={{ __html: decodeURIComponent(customJsWithoutMeta) }}
            />
          )}
          <div className={CLASS_NAME_FOR_HERE_API_DOM_ELEMENT} dangerouslySetInnerHTML={{ __html: '' }} />

        </>
      );
    }
}
