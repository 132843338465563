import {
  createContext, useContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { MODE_DELIVERY, MODE_LOCAL } from '../const/orders';
import { saveDeliveryMode } from '../helpers/catalog';
import { isBrowser } from '../helpers/formatPrice';
import { settingsProps } from '../helpers/propTypes';

export const SettingsContext = createContext(null);
export const useSettings = () => useContext(SettingsContext);

export function SettingsProvider({ value, children }) {
  const { query } = useRouter();
  const { mode } = query;

  const checkDelivery = (modeChecked) => modeChecked === MODE_DELIVERY;
  const hasShoppingCart = (valueChecked) => valueChecked?.showCart || false;

  const defaultValue = mode ? (checkDelivery(mode) && hasShoppingCart(value)) : hasShoppingCart(value);

  const [isDeliveryMode, setDeliveryMode] = useState(defaultValue);

  const settings = useMemo(() => ({
    ...value,
    isDeliveryMode,
    setDeliveryMode,
  }), [isDeliveryMode]);

  if (isBrowser()) {
    saveDeliveryMode(isDeliveryMode ? MODE_DELIVERY : MODE_LOCAL);
  }

  return (
  // eslint-disable-next-line react/react-in-jsx-scope
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  value: settingsProps,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
