import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import l from '../../../helpers/lang';

export default function BlockShop({
  logo, name, phone, email,
}) {
  return (
    <div className="container">
      <div className="row mt-10">
        <div className="col-12">
          <div className="text-center">
            {Boolean(logo) && (
            <Image
              itemProp="image"
              className="rounded with-placeholder"
              src={logo}
              alt={name}
              width={112}
              height={112}
              objectFit="cover"
            />
            )}
            <h1>{name}</h1>
            {Boolean(phone) && (
            <Link className="text-decoration-none mt-7 d-block" href={`tel:${phone}`}>{phone}</Link>
            )}
            {Boolean(email) && (
            <Link className="text-decoration-none mt-2 d-block" href={`mailto:${email}`}>{email}</Link>
            )}
          </div>
        </div>
      </div>

      <div className="row fixed-bottom">
        <div className="col-12">
          <div className="px-5 text-center">
            <div
              className="border-top p-5 text-secondary"
              dangerouslySetInnerHTML={{ __html: l('delivery.blockShop.blockShopNotice') }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

BlockShop.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
};

BlockShop.defaultProps = {
  logo: '',
  name: '',
  phone: '',
  email: '',
};
