import { IncomingMessage } from 'http';
import getConfig from 'next/config';
import Rollbar from 'rollbar';

import { isBrowser, isDevelopment } from './environment';

/**
 * The method is called before sending the log payload to the server.
 * In this method, you can modify the payload.
 *
 * @param {Rollbar.Dictionary} payload
 * @return {void}
 */
export function transformClientSide(payload) {
  if (payload?.body?.message?.body) {
    // eslint-disable-next-line no-param-reassign
    payload.fingerprint = payload.body.message.body;
  } else if (payload?.body?.trace?.exception?.description) {
    // eslint-disable-next-line no-param-reassign
    payload.fingerprint = payload.body.trace.exception.description;
  } else if (payload?.body?.trace?.exception?.message) {
    // eslint-disable-next-line no-param-reassign
    payload.fingerprint = payload.body.trace.exception.message;
  }
}

/**
 * The method is called before sending the log payload to the server.
 * In this method, you can modify the payload.
 *
 * @param {Rollbar.Dictionary} payload
 * @return {void}
 */
export function transformServerSide(payload) {
  if (payload?.body?.trace_chain?.[0]?.exception?.description) {
    // eslint-disable-next-line no-param-reassign
    payload.fingerprint = payload.body.trace_chain[0].exception.description;
  } else if (payload?.body?.trace_chain?.[0]?.exception?.message) {
    // eslint-disable-next-line no-param-reassign
    payload.fingerprint = payload.body.trace_chain[0].exception.message;
  }
}

class RollbarLogger {
  constructor() {
    /** @type {Rollbar|null} */
    this.rollbar = null;
  }

    /**
     * The method initializes the Rollbar instance with unknown person.id and person.username.
     */
    init = () => {
      const { serverRuntimeConfig } = getConfig();

      if (!isBrowser() && !isDevelopment() && !this.rollbar) {
        this.rollbar = new Rollbar({
          accessToken: serverRuntimeConfig.rollbarServerToken,
          environment: 'b2c_server',
          captureUncaught: true,
          captureUnhandledRejections: true,
          uncaughtErrorLevel: 'critical',
          payload: {
            client: {
              javascript: {
                guess_uncaught_frames: true,
                source_map_enabled: true,
              },
            },
            person: {
              id: 'unknown',
              username: 'unknown',
            },
          },
          transform: transformServerSide,
        });
      }
    };

    /**
     * The method configures the Rollbar instance with the provided settings.
     *
     * @param {import('../types/api').ApiHashSettings | {}} settings
     */
    configure = (settings) => {
      const { posAccountNumber = 'unknown', posAccountName = 'unknown' } = settings;

      if (
        !isBrowser()
            && !isDevelopment()
            && this.rollbar
            && this.rollbar?.options?.payload?.person?.id !== String(posAccountNumber)
            && this.rollbar?.options?.payload?.person?.username !== String(posAccountName)
      ) {
        this.rollbar.configure({
          payload: {
            person: {
              id: String(posAccountNumber),
              username: String(posAccountName),
            },
          },
        });
      }
    };

    /**
     * @param {string} level
     * @param {string|Error} message
     * @param {*} data
     */
    logWrite = (level, message, ...data) => {
      // Server-side logging
      if (this.rollbar) {
        this.rollbar[level](message, ...data);

        return;
      }

      // Client-side logging
      if (isBrowser() && window.Rollbar) {
        window.Rollbar[level](message, ...data);

        return;
      }

      const dataWithoutIncomingMessage = Object.values(data)?.filter((item) => !(item instanceof IncomingMessage));

      // Fallback to console logging
      // eslint-disable-next-line no-console
      console.log(`[${level.toUpperCase()}] ${message}`, ...dataWithoutIncomingMessage);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    debug = (message, ...data) => {
      this.logWrite('debug', message, ...data);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    info = (message, ...data) => {
      this.logWrite('info', message, ...data);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    log = (message, ...data) => {
      this.logWrite('log', message, ...data);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    warning = (message, ...data) => {
      this.logWrite('warning', message, ...data);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    warn = (message, ...data) => {
      this.logWrite('warn', message, ...data);
    };

    /**
     * @param {string|Error} message
     * @param {*} data
     */
    error = (message, ...data) => {
      this.logWrite('error', message, ...data);
    };

    /**
     * Note: The critical level is used to log unhandled exceptions.
     *
     * @param {string|Error} message
     * @param {*} data
     */
    critical = (message, ...data) => {
      this.logWrite('critical', message, ...data);
    };
}

export default new RollbarLogger();
