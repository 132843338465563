let lang = 'ru';
let currency = { symbol: '₴', iso_code: 'UAH' };

export function setCurrency(newCurrency, newLang) {
  lang = newLang;
  currency = newCurrency;
}

/**
 * @param price
 * @returns {*}
 */
export function f$(price) {
  let { symbol } = currency;

  if (currency.iso_code === 'UAH') {
    symbol = 'грн';
  }
  if (currency.iso_code === 'RUB') {
    symbol = 'р.';
  }
  if (currency.iso_code === 'AMD') {
    symbol = '֏';
  }

  const formattedNumber = (new Intl.NumberFormat(lang, {})).format(price);

  // Для левосторонних валют
  if (currency.iso_code === 'USD') {
    return `${symbol}${formattedNumber}`;
  }

  if (currency.iso_code === 'MXN') {
    return `${symbol}${formattedNumber} MXN`;
  }

  return `${formattedNumber}\u00A0${symbol}`;
}

/**
 * @deprecated use isBrowser from environment.js
 * @return {boolean}
 */
export function isBrowser() {
  return typeof window !== 'undefined';
}

export function plural(number, strings) {
  if (number === 1) return strings[0];
  if (number > 1 && number < 5) return strings[1];

  return strings[2];
}

/**
 *
 * @param {productProps} product
 * @returns {price: any, hasPricePrefix: boolean}}
 */
export function getPrice(product) {
  let hasPricePrefix = false;
  let { price } = product;
  const { productModifications, group_modifications: groupModifications } = product;

  const hasProductModifications = Boolean(productModifications);
  const hasGroupModifications = Boolean(groupModifications);

  if (!hasProductModifications && !hasGroupModifications) {
    return { hasPricePrefix, price };
  }

  if (hasProductModifications) {
    const productModificationPrices = productModifications.map((mod) => mod.price);
    const minProductPrice = Math.min(...productModificationPrices);
    const maxProductPrice = Math.max(...productModificationPrices);

    price = minProductPrice;
    hasPricePrefix = productModificationPrices.length > 1 && minProductPrice !== maxProductPrice;

    return { hasPricePrefix, price };
  }

  const requiredGroups = groupModifications.filter((group) => group.num_min > 0);
  const allGroupsRequired = requiredGroups.length === groupModifications.length;

  requiredGroups.forEach((group) => {
    const { num_min: numMin, num_max: numMax, modifications } = group;
    const modificationPrices = modifications.map((mod) => mod.price);
    const minModificationPrice = Math.min(...modificationPrices);
    const maxModificationPrice = Math.max(...modificationPrices);

    price += minModificationPrice * numMin;

    if (!allGroupsRequired || numMin !== numMax || minModificationPrice !== maxModificationPrice) {
      hasPricePrefix = true;
    }
  });

  if (!requiredGroups.length || !allGroupsRequired) {
    hasPricePrefix = true;
  }

  return { hasPricePrefix, price };
}
