import React from 'react';
import Head from 'next/head';

export default class WarMessage extends React.Component {
  render() {
    return (
      <div className="container">
        <Head>
          <title>Война против Украины</title>
          <link rel="shortcut icon" href="/Ukraine.ico" type="image/x-icon" />
        </Head>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 mt-lg-6 mt-3">
            <h1 className="fw-bolder mb-3">
              Война против Украины
            </h1>
            <p>
              Этот сайт взломан. Сообщение от Poster POS: 24 февраля РФ начала войну против Украины.
            </p>
            <p>
              24 февраля РФ начала войну против Украины. Не «миротворческую» или «спасательную» операцию, как рассказывают в
              СМИ, а полномасштабную войну против всего народа Украины, государства Украина.
            </p>
            <p>
              Это касается не только Донбасса, это полномасштабное вторжение со всех границ РФ и РБ, с использованием авиации,
              танков, крылатых и баллистических ракет.
            </p>
            <p>
              Погибло уже больше 350 мирных жителей.
            </p>
            <p className="fw-bold">
              Хотите что-то изменить? Выходите на улицы — нет войне!
            </p>
          </div>
          <div className="col-12 mb-5">
            <video className="mt-3 video w-100" playsInline muted loop autoPlay controls src="/war.mp4" />
          </div>
        </div>
      </div>
    );
  }
}
