export const KEY_BACK_SPACE = 8;
export const KEY_TAB = 9;
export const KEY_ENTER = 13;
export const KEY_ESCAPE = 27;
export const KEY_UP = 38;
export const KEY_DOWN = 40;

export const STORAGE_AVAILABLE = 'localStorageAvailable';
export const STORAGE_PRODUCTS = 'products';
export const STORAGE_CATALOG = 'catalog';
export const STORAGE_SETTINGS = 'settings';
export const STORAGE_NAVBAR = 'navbar';
export const STORAGE_SLIDER = 'slider';
export const STORAGE_CART = 'cart';
export const STORAGE_CLIENT = 'client';
export const STORAGE_SPOTS = 'spots';
export const STORAGE_CONTACTS = 'contacts';
export const STORAGE_CART_INFO = 'cartInfo';

export const DELIVERY_TYPE_COURIER = 'courier';
export const DELIVERY_TYPE_PICKUP = 'pickup';

export const ORDER_CONFIRM_TYPE_SUBMIT = 'submit';
export const ORDER_CONFIRM_TYPE_REDIRECT = 'redirect';

export const ORDER_STATUS_SUCCESS = 'success';
export const ORDER_STATUS_FAILED = 'failed';

export const GA_TRACKER_ID = 'UA-42569912-5';

export const DELIVERY_THEME_AUTO = 0;
export const DELIVERY_THEME_LIGHT = 1;
export const DELIVERY_THEME_DARK = 2;
// при изменении значения также поменять и в проекте poster shop константу CONFIG_DELIVERY_DEFAULT_COLOR
export const THEME_DEFAULT_COLOR = '#FFC12E';

export const API_STATUS_SUCCESS = 'success';
export const API_STATUS_FAIL = 'failure';

export const API_MESSAGE_UNAUTHORIZED = 'Unauthorized';
export const API_MESSAGE_NOT_FOUND = 'Not found';

export const PRODUCTION_ENVIRONMENT = 'prod';
export const DEV_ENVIRONMENT = 'dev';

export const CLASS_NAME_FOR_HERE_API_DOM_ELEMENT = 'here-js-scripts';

export const OLD_PS_DOMAIN = 'postershop.me';

export const NEW_PS_DOMAIN = 'ps.me';

export const PAYMENT_STRIPE_METHOD = 'stripe';
export const PAYMENT_LIQPAY_METHOD = 'liqpay';

export const CURRENCY_MEXICAN_PESO = 'MXN';
export const ORDER_MIN_CARD_MX = 5;

export const FONDY_WIDGET_THEME_LIGHT = 'light';
export const FONDY_WIDGET_THEME_DARK = 'dark';

export const QR_PAYMENT_STATUS_DEFAULT = 0;
export const QR_PAYMENT_STATUS_NEW = 1;
export const QR_PAYMENT_STATUS_PAID = 2;
export const QR_PAYMENT_STATUS_FAILED = 3;
export const QR_PAYMENT_STATUS_WAIT_SECURE = 11;
export const QR_PAYMENT_STATUS_ORDER_ALREADY_PAYED = 4;
export const QR_PAYMENT_STATUS_ORDER_CLOSE_FAILED = 5;

export const QR_PAYMENT_STATUSES_DONE = [
  QR_PAYMENT_STATUS_PAID,
  QR_PAYMENT_STATUS_FAILED,
  QR_PAYMENT_STATUS_ORDER_ALREADY_PAYED,
  QR_PAYMENT_STATUS_ORDER_CLOSE_FAILED,
];

export const QR_PAYMENT_STATUSES_FAILED = [
  QR_PAYMENT_STATUS_FAILED,
  QR_PAYMENT_STATUS_ORDER_ALREADY_PAYED,
  QR_PAYMENT_STATUS_ORDER_CLOSE_FAILED,
];

export const PAYMENT_PROVIDER_FONDY = 'fondy';
export const PAYMENT_PROVIDER_FONDY_ID = 1;

export const PAYMENT_PROVIDER_LIQPAY = 'liqpay';
export const PAYMENT_PROVIDER_LIQPAY_ID = 2;

export const ACTION_CHANGE_THEME = 'load-colors';

export const ACTION_ADJUST_SIZE = 'preview-adjustment';

export const SITE_INFO_SETTINGS = 'settings';
export const SITE_INFO_CONTACTS = 'contacts';
export const SITE_INFO_NAVBAR = 'navbar';
export const SITE_INFO_SLIDER = 'slider';
