import PropTypes from 'prop-types';

export const settingsProps = PropTypes.shape({
  lang: PropTypes.string,
  siteLang: PropTypes.string,
  accountEmail: PropTypes.string,
  email: PropTypes.string,
  shouldRedirectToPsMe: PropTypes.bool,
  favicon: PropTypes.string,
  logo: PropTypes.string,
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  country: PropTypes.string,
  currency: PropTypes.shape({
    currency_id: PropTypes.number,
    pos_id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    symbol: PropTypes.string,
    iso_code: PropTypes.string,
    iso_number: PropTypes.string,
  }),
  shopName: PropTypes.string,
  customDomain: PropTypes.string,
  siteDomain: PropTypes.string,
  staticPageUrlPrefix: PropTypes.string,
  freeDeliveryOrderAmount: PropTypes.number,
  deliveryPrice: PropTypes.number,
  minOrderAmount: PropTypes.number,
  posAccountNumber: PropTypes.string,
  posAccountName: PropTypes.string,
  customJS: PropTypes.string,
  phone: PropTypes.string,
  mainPageSeoTitle: PropTypes.string,
  mainPageSeoDescription: PropTypes.string,
  deliveryColor: PropTypes.string,
  deliveryColorTheme: PropTypes.number,
  blockShop: PropTypes.bool,
  showCart: PropTypes.bool,
  showSiteMode: PropTypes.bool,
  customCssDelivery: PropTypes.string,
  successfulPaymentText: PropTypes.string,
  showDisabledOrdersNotice: PropTypes.bool,
  isFeedbackConnected: PropTypes.bool,
  isPaymentConnected: PropTypes.bool,
  paymentSettings: PropTypes.shape({
    availableProviders: PropTypes.objectOf(PropTypes.string),
    paymentAccounts: PropTypes.instanceOf(Array),
    currentProvider: PropTypes.number,
  }),
  newDesignMenu: PropTypes.bool,
  useCloudFlareImage: PropTypes.bool,
  useLiqpay: PropTypes.bool,
  contacts: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    additional: PropTypes.string,
    markers: PropTypes.arrayOf(PropTypes.shape({
      lat: PropTypes.string,
      lng: PropTypes.string,
    })),
    fb: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    twitter: PropTypes.string,
    telegram: PropTypes.string,
    tiktok: PropTypes.string,
    wifiName: PropTypes.string,
    wifiPass: PropTypes.string,
    workingTime: PropTypes.shape({
      hours: PropTypes.string,
      allDay: PropTypes.bool,
    }),
  }),
  accountPhone: PropTypes.string,
  featureFlags: PropTypes.instanceOf(Array),
  deliveryData: PropTypes.shape({
    deliveries: PropTypes.arrayOf(PropTypes.shape({
      delivery_id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      enabled: PropTypes.bool,
      spotIds: PropTypes.arrayOf(PropTypes.number),
    })),
    enabledOrders: PropTypes.bool,
    deliveryPrice: PropTypes.number,
    useDeliveryNearestSpot: PropTypes.bool,
    freeDeliveryOrderAmount: PropTypes.number,
    currency: PropTypes.shape({
      currency_id: PropTypes.number,
      pos_id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
      symbol: PropTypes.string,
      iso_code: PropTypes.string,
      iso_number: PropTypes.string,
    }),
    spots: PropTypes.arrayOf(PropTypes.shape({
      spot_id: PropTypes.number,
      pos_id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
    })),
  }),
  paymentsType: PropTypes.arrayOf(PropTypes.string),
  isShopWithCart: PropTypes.bool,
  hubBanner: PropTypes.string,
});

export const dishModificationProps = PropTypes.shape({
  modification_id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.number,
});

export const modificationGroupProps = PropTypes.shape({
  modification_group_id: PropTypes.number,
  name: PropTypes.string,
  num_min: PropTypes.number,
  num_max: PropTypes.number,
  type: PropTypes.number,
  modifications: PropTypes.arrayOf(dishModificationProps),
});

export const spotProps = PropTypes.shape({
  spot_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  address: PropTypes.string,
  pos_id: PropTypes.number,
});

export const spotsProps = PropTypes.arrayOf(spotProps);

export const deliveryProps = PropTypes.shape({
  delivery_id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  spots: PropTypes.arrayOf(spotProps),
});

export const cartProductModificationProps = PropTypes.shape({
  count: PropTypes.number,
  ingredient_id: PropTypes.number,
  modificator_id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  price_format: PropTypes.string,
  sum: PropTypes.number,
  sum_format: PropTypes.string,
});

export const cartProductProps = PropTypes.shape({
  cart_product_id: PropTypes.number,
  product_id: PropTypes.number,
  ingredient_id: PropTypes.number,
  dish: PropTypes.shape({}),
  name: PropTypes.string,
  url: PropTypes.string,
  price: PropTypes.number,
  price_format: PropTypes.string,
  image: PropTypes.string,
  big_image: PropTypes.string,
  count: PropTypes.number,
  sum: PropTypes.number,
  unit_type: PropTypes.string,
  sum_format: PropTypes.string,
  leftovers: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  modificators: PropTypes.arrayOf(cartProductModificationProps),
});

export const cartProps = PropTypes.shape({
  cart_id: PropTypes.number,
  createDate: PropTypes.string,
  updateDate: PropTypes.string,
  count: PropTypes.number,
  sum: PropTypes.number,
  sum_format: PropTypes.string,
  deliveries: PropTypes.arrayOf(deliveryProps),
  payments: PropTypes.shape({}),
  freeDeliveryOrderAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deliveryPrice: PropTypes.number,
  products: PropTypes.arrayOf(cartProductProps),
});

export const productModificationProps = PropTypes.shape({
  modificator_id: PropTypes.number,
  name: PropTypes.string,
  sort_order: PropTypes.number,
  price: PropTypes.number,
});

export const tagProps = PropTypes.shape({
  name: PropTypes.string,
  color: PropTypes.string,
});

export const productProps = PropTypes.shape({
  product_id: PropTypes.number,
  pos_id: PropTypes.number,
  name: PropTypes.string,
  photo: PropTypes.string,
  description: PropTypes.string,
  isWeight: PropTypes.bool,
  popular: PropTypes.bool,
  sort_order: PropTypes.number,
  price: PropTypes.number,
  categoryPosId: PropTypes.number,
  categoryName: PropTypes.string,
  parentCategoryId: PropTypes.number,
  parentCategoryName: PropTypes.string,
  tags: PropTypes.arrayOf(tagProps),
  productModifications: PropTypes.arrayOf(productModificationProps),
  group_modifications: PropTypes.arrayOf(modificationGroupProps),
});

export const categoryProps = PropTypes.shape({
  categoryPosId: PropTypes.number,
  categoryParentId: PropTypes.number,
  categoryUrl: PropTypes.string,
  categoryName: PropTypes.string,
});

export const headerProps = PropTypes.shape({
  logo: PropTypes.string,
  title: PropTypes.string,
});

export const pageShape = PropTypes.shape({
  static_page_id: PropTypes.number,
  title: PropTypes.string,
  full_url: PropTypes.string,
});

export const navbarProps = PropTypes.shape({
  pages: PropTypes.arrayOf(pageShape),
});

export const sliderProps = PropTypes.arrayOf(PropTypes.shape({
  slider_id: PropTypes.number,
  slide_id: PropTypes.number,
  sort: PropTypes.number,
  link: PropTypes.string,
  image: PropTypes.string,
}));

export const workingTimeProps = PropTypes.shape({
  workingTimes: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.number,
    opening_time: PropTypes.string,
    closing_time: PropTypes.string,
    all_day: PropTypes.number,
    lat: PropTypes.string,
    lng: PropTypes.string,
  })),
  country: PropTypes.string,
  serverTimeFormatted: PropTypes.string,
  requestTime: PropTypes.number,
  serverTimeUnix: PropTypes.number,
});

export const pageProps = PropTypes.shape({
  full_url: PropTypes.string.isRequired,
  seo_description: PropTypes.string.isRequired,
  seo_title: PropTypes.string.isRequired,
  shop_id: PropTypes.number.isRequired,
  sort_order: PropTypes.number.isRequired,
  static_page_id: PropTypes.number.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
});

export const clientProps = PropTypes.shape({
  name: PropTypes.string,
  phone: PropTypes.string,
  deliveryType: PropTypes.string,
});

export const contactsProps = PropTypes.shape({
  address: PropTypes.string,
  email: PropTypes.string,
  fb: PropTypes.string,
  googlePlus: PropTypes.string,
  instagram: PropTypes.string,
  ok: PropTypes.string,
  phone: PropTypes.string,
  skype: PropTypes.string,
  telegram: PropTypes.string,
  twitter: PropTypes.string,
  vk: PropTypes.string,
  youtube: PropTypes.string,
});

export const paymentDataProps = PropTypes.shape({
  merchant_id: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  order_desc: PropTypes.string.isRequired,
  response_url: PropTypes.string.isRequired,
  server_callback_url: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
});

export const catalogItemProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  parentId: PropTypes.number,
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(productProps),
});

// указать выше не получится,
// js выдает ошибку has not been initialized
catalogItemProps.categories = PropTypes.arrayOf(catalogItemProps);

export const catalogProps = PropTypes.arrayOf(catalogItemProps);

export const mapMarkerShape = PropTypes.shape({
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
});

export const spotContactShape = PropTypes.shape({
  posId: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const apiContactProps = PropTypes.shape({
  address: PropTypes.string.isRequired,
  mapMarkers: PropTypes.arrayOf(mapMarkerShape).isRequired,
  spots: PropTypes.arrayOf(spotContactShape).isRequired,
  email: PropTypes.string,
  socials: contactsProps.isRequired,
});

export const productShape = PropTypes.shape({
  product_name: PropTypes.string.isRequired,
  product_sum: PropTypes.number.isRequired,
  modificator_name: PropTypes.string,
});

export const transactionShape = PropTypes.shape({
  transaction_id: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(productShape),
  serviceCharge: PropTypes.number.isRequired,
  clientDiscount: PropTypes.number.isRequired,
  currency: PropTypes.string,
});
