import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

export const NavigationContext = createContext({});
export const useNavigationPosition = () => useContext(NavigationContext);

export function NavigationProvider({ children }) {
  const [topOffset, setTopOffset] = useState(0);

  const value = useMemo(() => ({
    topOffset,
    setTopOffset,
  }), [topOffset]);

  return (
  // eslint-disable-next-line react/react-in-jsx-scope
    <NavigationContext.Provider
      value={value}
    >
      {children}
    </NavigationContext.Provider>
  );
}

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
