import _ from 'underscore';

import uk from './uk/siteDelivery.json';
import ru from './ru/siteDelivery.json';
import en from './en/siteDelivery.json';
import mx from './mx/siteDelivery.json';

let activeLang = 'uk';
const langs = {
  uk, ru, en, mx,
};

export function setLang(lang) {
  if (langs[lang]) {
    activeLang = lang;
  }
}

export default function l(index) {
  const lang = langs[activeLang];
  let str;

  if (index === undefined) {
    return activeLang;
  }

  str = lang[index];

  if (!str || str.toString().trim() === '') {
    str = `(!) ${index}`;
  }

  if (arguments.length > 1) {
    if (typeof arguments[1] === 'object') {
      _.each(arguments[1], (val, key) => {
        str = str.replace(`%${key}`, val);
      });
    } else {
      for (let i = 1; i < arguments.length; i += 1) {
        str = str.replace('%s', arguments[i]);
      }
    }
  }

  return str;
}
