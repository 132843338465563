import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { calculateColors, hexToRGB } from '../../../helpers/color';
import {
  ACTION_ADJUST_SIZE,
  ACTION_CHANGE_THEME,
  DELIVERY_THEME_AUTO,
  DELIVERY_THEME_DARK,
  DELIVERY_THEME_LIGHT,
  THEME_DEFAULT_COLOR,
} from '../../../helpers/constants';

const themeNames = {
  [DELIVERY_THEME_AUTO]: 'auto',
  [DELIVERY_THEME_LIGHT]: 'light',
  [DELIVERY_THEME_DARK]: 'dark',
};

/**
 *
 * @param {string} hex
 * @returns {Record<1 | 2, Record<string, { r: number, g: number, b: number, a: number }>>}
 */
const computeColors = (hex) => {
  const { whiteColors, darkColors } = calculateColors(hexToRGB(hex));
  return {
    [DELIVERY_THEME_LIGHT]: whiteColors,
    [DELIVERY_THEME_DARK]: darkColors,
  };
};

export default function ThemeStyler({
  defaultThemeId = DELIVERY_THEME_AUTO,
  defaultAccentHex = THEME_DEFAULT_COLOR,
}) {
  const [themeId, setThemeId] = useState(defaultThemeId);
  const [colors, setColors] = useState(computeColors(defaultAccentHex));

  /**
   * Обновить тему или акцентный цвет.
   *
   * Принимает:
   * id темы (0-авто, 1-светлая, 2-темная)
   * hex акцентного цвета, например #fff
   *
   * @param {{theme: number, hex: string}} params
   */
  const updateColors = ({
    theme = DELIVERY_THEME_AUTO,
    hex = THEME_DEFAULT_COLOR,
  }) => {
    setThemeId(theme);
    setColors(computeColors(hex));
    document.body.dataset.bsTheme = themeNames[theme];
  };

  /**
   * Обрабатываем сообщения из iframe.
   * Принимает json сообщение в формате {"action": "load-colors", "hex": "#fff", "theme": 1 },
   * Умеет:
   * - Менять тему (светлая/темная/авто)
   * - Добавлять отступы (легаси код)
   *
   * Сообщения не изо домена joinposter игнорируются.
   */
  const handleIframeMessage = (e) => {
    const { origin, data } = e;

    if (/(shop\.joinposter\.com|shop\.poster\.pos)/.test(origin) === false) return;

    try {
      const {
        action = ACTION_CHANGE_THEME,
        hex = THEME_DEFAULT_COLOR,
        theme = DELIVERY_THEME_AUTO,
      } = JSON.parse(data);

      if (action === ACTION_CHANGE_THEME) {
        updateColors({ theme, hex });
      } else if (action === ACTION_ADJUST_SIZE) {
        document.body.classList.add('pt-6');
      }
    } catch (e) {}
  };

  useEffect(() => {
    document.body.dataset.bsTheme = themeNames[themeId];
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  });

  /**
   * Обработчик для автоматической темы.
   */
  const handleAutoTheme = () => {
    if (themeId !== DELIVERY_THEME_AUTO) {
      return;
    }
    const newThemeId = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? DELIVERY_THEME_DARK
      : DELIVERY_THEME_LIGHT;
    setThemeId(newThemeId);
  };

  useEffect(() => {
    if (themeId !== DELIVERY_THEME_AUTO) {
      return;
    }

    handleAutoTheme();
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleAutoTheme);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleAutoTheme);
    };
  }, [themeId, handleAutoTheme]);

  /**
   * Преобразовать RGB объект в строку для css переменной.
   *
   * @param {{r: number, g: number, b: number}} rgbObj
   * @returns {string}
   */
  const rgbToCssVar = (rgbObj) => `${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}`;

  /**
   * @param {Record<string, { r: number, g: number, b: number, a: number }>} colorsList
   * @returns {string}
   */
  const printCssVars = (colorsList) => {
    const computed = Object.entries(colorsList)
      .map(([name, rgb]) => `--cs-${name}: ${rgbToCssVar(rgb)} !important;`)
      .join('');

    return [
      computed,
      `--bs-primary: rgb(${rgbToCssVar(colorsList.main)});`,
      `--bs-primary-rgb: ${rgbToCssVar(colorsList.main)};`,
    ].join('\n');
  };

  return (
    <Head>
      <style type="text/css">
        {themeId !== DELIVERY_THEME_AUTO
          ? `:root{ ${printCssVars(colors[themeId])}}`
          : `:root{ ${printCssVars(
            colors[DELIVERY_THEME_LIGHT],
          )} } @media (prefers-color-scheme: dark) { :root{${printCssVars(
            colors[DELIVERY_THEME_DARK],
          )}}}`}
      </style>
    </Head>
  );
}
