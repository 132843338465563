import {
  darken, lighten, luminosity, saturation,
} from 'color-blend';
import { DELIVERY_THEME_AUTO, DELIVERY_THEME_LIGHT } from './constants';

export const hexToRgba = (hexValue, alpha = 1) => {
  const hex = hexValue.charAt(0) !== '#' ? `#${hexValue}` : hexValue;
  const isValidHex = (hexCode) => /^#([A-Fa-f0-9]{6})$/.test(hexCode);
  const validHex = isValidHex(hex) ? hex : '#cdaf12';
  const r = parseInt(validHex.slice(1, 3), 16);
  const g = parseInt(validHex.slice(3, 5), 16);
  const b = parseInt(validHex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const hexToRGB = (hex) => ({
  r: parseInt(hex[1] + hex[2], 16),
  g: parseInt(hex[3] + hex[4], 16),
  b: parseInt(hex[5] + hex[6], 16),
  a: 1,
});

export const RGBToHex = ({ r, g, b }) => {
  let rH = r.toString(16);
  let gH = g.toString(16);
  let bH = b.toString(16);

  if (rH.length === 1) rH = `0${rH}`;
  if (gH.length === 1) gH = `0${gH}`;
  if (bH.length === 1) bH = `0${bH}`;

  return `#${rH}${gH}${bH}`;
};

export const luminance = ({ r, g, b }) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : ((v + 0.055) / 1.055) ** 2.4;
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);

  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.25);
};

export const calculateColors = (source) => {
  const mainTextColor = (source.r * 0.299 + source.g * 0.587 + source.b * 0.114) > 186
    ? {
      r: 29, g: 27, b: 32, a: 1,
    }
    : {
      r: 255, g: 255, b: 255, a: 1,
    };
  const whiteColors = {
    main: source,
    'darker-main': darken(source, {
      r: 0, g: 0, b: 0, a: 0.22,
    }),
    'lighten-main': lighten(source, {
      r: 255, g: 255, b: 255, a: 0.85,
    }),
    'slightly-lighten-main': lighten(source, {
      r: 255, g: 255, b: 255, a: 0.15,
    }),
    background: luminosity(source, {
      r: 255, g: 255, b: 255, a: 1,
    }),
    'alt-background': saturation(luminosity(source, {
      r: 245, g: 245, b: 245, a: 1,
    }), {
      r: 255, g: 250, b: 250, a: 1,
    }),
    'lighter-inactive': saturation(luminosity(source, {
      r: 217, g: 217, b: 217, a: 1,
    }), {
      r: 255, g: 245, b: 245, a: 1,
    }),
    'secondary-text': saturation(luminosity(source, {
      r: 140, g: 140, b: 140, a: 1,
    }), {
      r: 255, g: 250, b: 250, a: 1,
    }),
    font: saturation(darken(source, {
      r: 44, g: 37, b: 37, a: 1,
    }), {
      r: 43, g: 36, b: 36, a: 1,
    }),
    'main-text': mainTextColor,
  };

  let whiteContrast = contrast(whiteColors.main, whiteColors.background);
  let blackContrast = contrast(whiteColors.main, { r: 0, g: 0, b: 0 });

  whiteColors['btn-font'] = whiteContrast > blackContrast ? whiteColors.background : {
    r: 0, g: 0, b: 0, a: 1,
  };

  const darkColors = {
    main: source,
    'darker-main': lighten(source, {
      r: 255, g: 255, b: 255, a: 0.45,
    }),
    'lighten-main': darken(source, {
      r: 0, g: 0, b: 0, a: 0.65,
    }),
    'slightly-lighten-main': lighten(source, {
      r: 0, g: 0, b: 0, a: 0.35,
    }),
    background: saturation(darken(source, {
      r: 38, g: 38, b: 38, a: 0.9,
    }), {
      r: 43, g: 36, b: 36, a: 1,
    }),
    'alt-background': saturation(luminosity(source, {
      r: 67, g: 67, b: 67, a: 1,
    }), {
      r: 255, g: 240, b: 240, a: 1,
    }),
    'lighter-inactive': saturation(luminosity(source, {
      r: 153, g: 153, b: 153, a: 1,
    }), {
      r: 255, g: 240, b: 240, a: 1,
    }),
    'secondary-text': saturation(luminosity(source, {
      r: 140, g: 140, b: 140, a: 1,
    }), {
      r: 255, g: 250, b: 250, a: 1,
    }),
    font: luminosity(source, {
      r: 250, g: 250, b: 250, a: 1,
    }),
    'main-text': mainTextColor,
  };

  whiteContrast = contrast(darkColors.main, darkColors.background);
  blackContrast = contrast(darkColors.main, { r: 255, g: 255, b: 255 });

  darkColors['btn-font'] = whiteContrast > blackContrast ? darkColors.background : {
    r: 255, g: 255, b: 255, a: 1,
  };

  return { darkColors, whiteColors };
};

export const getTheme = (currentTheme) => {
  if (currentTheme === DELIVERY_THEME_AUTO && typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  return currentTheme === DELIVERY_THEME_LIGHT ? 'light' : 'dark';
};
