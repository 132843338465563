import { LOCAL_STORAGE_KEY_MODE, MODE_DELIVERY, MODE_LOCAL } from '../const/orders';

export function listToTree(list, parentKey = 'parentId', childrenKey = 'childrens') {
  const map = {};
  const roots = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i][childrenKey] = []; // initialize the children
  }

  for (let i = 0; i < list.length; i += 1) {
    const node = list[i];
    if (node[parentKey] !== null) {
      // if you have dangling branches check that map[node.parentId] exists

      list[map[node.parentId]]?.[childrenKey].push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function prepareCategoryTree(products) {
  const catalog = new Map();

  for (const product of products) {
    const { parentCategoryId } = product;

    if (!catalog.has(product.categoryPosId)) {
      catalog.set(product.categoryPosId, {
        id: product.categoryPosId,
        parentId: product.parentCategoryId,
        label: product.categoryName.trim(),
        slug: product.categoryUrl,
        categories: [],
        products: [],
      });
    }

    // фикс для pos_id = 0. Используется для "Загальна" категория
    if (typeof parentCategoryId === 'number' && !catalog.has(parentCategoryId)) {
      catalog.set(parentCategoryId, {
        id: parentCategoryId,
        parentId: null,
        label: product.parentCategoryName.trim(),
        slug: product.parentCategoryUrl,
        categories: [],
        products: [],
      });
    }

    const category = catalog.get(product.categoryPosId);
    category.products.push(product);
    catalog.set(product.categoryPosId, category);
  }

  const sortedCategories = [...catalog].map(([_, item]) => item);

  return listToTree(sortedCategories, 'parentId', 'categories');
}

export function isUseDeliveryMode() {
  try {
    const mode = localStorage.getItem(LOCAL_STORAGE_KEY_MODE);
    return mode != MODE_LOCAL;
  } catch (e) {
    return true;
  }
}

export function saveDeliveryMode(mode) {
  localStorage.setItem(LOCAL_STORAGE_KEY_MODE, mode);
}

export function switchDeliveryMode() {
  const mode = localStorage.getItem(LOCAL_STORAGE_KEY_MODE);
  saveDeliveryMode(mode === MODE_DELIVERY ? MODE_LOCAL : MODE_DELIVERY);
}
