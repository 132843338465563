import { DEV_ENVIRONMENT, PRODUCTION_ENVIRONMENT } from './constants';

export function isDevelopment() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === DEV_ENVIRONMENT;
}

export function isProduction() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === PRODUCTION_ENVIRONMENT;
}

export function isBrowser() {
  // TODO: should replace to `return process.browser`
  return typeof window !== 'undefined';
}
