import React from 'react';
import PropTypes from 'prop-types';

import RollbarLogger from '../../helpers/RollbarLogger';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      RollbarLogger.critical(error, { errorInfo });
    }

    render() {
      const { children } = this.props;
      const { hasError } = this.state;

      if (hasError) {
        return <ErrorPage />;
      }

      return children;
    }
}

export default ErrorBoundary;
