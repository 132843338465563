import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTurtle } from '@fortawesome/pro-duotone-svg-icons';

import Styles from './QrHubMessage.module.scss';

import l from '../../helpers/lang';

function QrHubMessage({
  icon: Icon, title, message, actionText, action,
}) {
  const router = useRouter();

  const reloadThePage = () => {
    router.reload();
  };

  const renderIcon = () => {
    if (typeof Icon === 'function') {
      return <Icon />;
    }
    if (typeof Icon === 'object' && Icon !== null && !Array.isArray(Icon)) {
      return <FontAwesomeIcon icon={Icon} className={`pb-7 ${Styles.iconStyle}`} />;
    }
    return <FontAwesomeIcon icon={faTurtle} className={`pb-7 ${Styles.iconStyle}`} />;
  };

  const actionHandler = action ?? reloadThePage;
  const actionButtonText = actionText ?? l('qr.tableHash/nothing.refreshButton');

  return (
    <div className="text-center pb-10">
      { renderIcon() }
      <div className="ms-2 fs-5">{title}</div>
      <div className="text-muted px-3 pt-3 pb-5 fs-6">{message}</div>
      <Button
        variant="outline-secondary"
        className="px-5"
        onClick={actionHandler}
      >
        {actionButtonText}
      </Button>
    </div>
  );
}

QrHubMessage.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  actionText: PropTypes.string,
  action: PropTypes.func,
};

export default QrHubMessage;
