import React from 'react';
import { Container } from 'react-bootstrap';
import { faTurtle } from '@fortawesome/pro-duotone-svg-icons';

import QrHubMessage from '../[tableHash]/QrHubMessage';
import l from '../../helpers/lang';

function ErrorPage({
  icon, title, text, action, actionText,
}) {
  const defaultTitle = l('qr.errorPage/noConnection.errorTitle');
  const defaultText = l('qr.errorPage/noConnection.errorText');

  return (
    <Container className="p-3 d-flex align-items-center justify-content-center svh-100">
      <QrHubMessage
        icon={icon}
        title={title ?? defaultTitle}
        message={text ?? defaultText}
        action={action}
        actionText={actionText}
      />
    </Container>
  );
}

export default ErrorPage;
